export const Bkn = require('./bkn.png');
export const Blizzard = require('./blizzard.png');
export const Dust = require('./dust.png');
export const Few = require('./few.png');
export const FzRain = require('./fzra.png');
export const Haze = require('./haze.png');
export const Ovc = require('./ovc.png');
export const RainShowersHi = require('./rain_showers_hi.png');
export const RainShowers = require('./rain_showers.png');
export const RainSleet = require('./rain_sleet.png');
export const RainSnow = require('./rain_snow.png');
export const Rain = require('./rain.png');
export const SkyClear = require('./skc.png');
export const Sleet = require('./sleet.png');
export const Smoke = require('./smoke.png');
export const SnowFzRain = require('./snow_fzra.png');
export const SnowSleet = require('./snow_sleet.png');
export const Snow = require('./snow.png');
export const ThunderStormHi = require('./tsra_hi.png');
export const ThunderStormSct = require('./tsra_sct.png');
export const ThunderStorm = require('./tsra.png');
export const WindBroken = require('./wind_bkn.png');
export const WindFew = require('./wind_few.png');
export const WindOvc = require('./wind_ovc.png');
export const WindSct = require('./wind_sct.png');
export const WindSkc = require('./wind_skc.png');
export const Hot = require('./wx_hot.png');
export const Hurricane = require('./wind_sct.png');
export const Tornado = require('./wx_tornado.png');
export const TropStorm = require('./wx_tstorm.png');